














































































































































































import {
  Component,
  Inject,
  InjectReactive,
  Vue,
} from 'vue-property-decorator';
import LoadingContent from '@/components/LoadingContent.vue';
import { AppStore } from '@/store/types/AppStore';
import { ApiFacade } from '@/services/ApiFacade';
import dayjs from 'dayjs';
import Subtitle from '@/components/Subtitle.vue';
import { requiredValidator } from '@/services/defaultFormValidators';
import { Lesson } from '@/models/entities/Lesson';
import { LessonStatus } from '@/types/Statuses';
import { Course } from '@/models/entities/Course';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { Bold, Italic, TiptapVuetify } from 'tiptap-vuetify';

@Component({
  components: {
    ErrorAlert,
    Subtitle,
    LoadingContent,
    TiptapVuetify,
  },
})
export default class LessonEditFormView extends Vue {
  @Inject() appStore!: AppStore;
  @Inject() apiFacade!: ApiFacade;

  @InjectReactive() course!: Course;

  isValid: boolean = false;
  isLoading: boolean = false;
  isInProcess: boolean = false;

  error: ErrorAlert | string | null = null;

  courseId!: number;

  startDate = '';
  isStartDatePickerShown = false;

  startTime = '';
  isStartTimePickerShown = false;

  description: string = '';
  editorExtensions = [Bold, Italic];

  duration = 40;

  rules = {
    requiredValidator,
  };

  userTimeZone = dayjs.tz.guess();

  mounted() {
    this.courseId = parseInt(this.$route.params.courseId, 10);
  }

  get formTitle() {
    return this.isNew
      ? 'Добавить занятие'
      : 'Редактирование занятие';
  }

  get isNew() {
    return this.$route.name === 'course-lesson-new';
  }

  get startDateFormatted() {
    if (!this.startDate) {
      return '';
    }
    return dayjs(this.startDate)
      .format('L');
  }

  close() {
    this.$emit('close');
    // this.$router.push({
    //   name: 'course-schedule',
    //   params: { id: String(this.courseId) },
    // });
  }

  handleCancel() {
    this.close();
  }

  async handleSave() {
    try {
      this.isInProcess = true;
      this.error = null;
      // const lesson = new Lesson();
      // lesson.status=LessonStatus.;
      const [hours, mins] = this.startTime.split(':')
        .map(Number);
      const startDateTime = new Date(this.startDate);
      startDateTime.setHours(hours, mins);
      const lesson = Lesson.createFromDto({
        lessonDate: startDateTime.toISOString(),
        status: LessonStatus.active,
        lessonDurationSeconds: this.duration * 60,
        description: this.description,
      });
      lesson.course = this.course;

      const newLesson = await this.apiFacade.createLesson(lesson);

      this.$emit('update-lesson', newLesson);
      this.close();
    } catch (err) {
      this.error = err;
    } finally {
      this.isInProcess = false;
    }
  }
}
